section.blog-section {
    .contact-bar-wrap {
        background: #000;
        padding: 100px 0px 100px 160px;
        @media screen and (max-width: 1600px) {
            & {
                padding: 100px 0px 100px 100px;
            }
        }
        @media screen and (max-width: 991px) {
            & {
                border: none !important;
                padding: 100px 30px;
            }
        }
    }
    .blog-wrap {
        background: #000;
        padding: 75px;

        @media screen and (max-width: 991px) {
            & {
                padding: 55px;
                border: none;
            }
        }

        @media screen and (max-width: 767px) {
            & {
                padding: 55px 15px;
            }
        }
    }
    &.has-contact {
        &:not(.has-faqs) {
            padding-top: 300px !important;
            @media screen and (max-width: 1600px) {
                & {
                    padding-top: 600px !important;
                }
            }
            @media screen and (max-width: 991px) {
                & {
                    padding-top: 100px !important;
                }
            }
        }
    }
}
