section.hero-section {
    min-height:400px;
    height: 890px;

    @media screen and (max-width: 1600px) {
        & {
            height: 620px;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            height: 63vw;
        }
    }
}